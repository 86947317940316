import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Route, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { BasicPopupComponent } from '../shared/popups/basic-popup/basic-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  dynamicFormList: any;
  selectedIndex = 0;
  showChildComponent = false;
  selectedTemplateID: any;
  isV2CaptchaRequired: boolean = false;
  siteKey: string = environment.reCaptcha2SiteKey

  public lat;
  public lng;
  zoom;

  constructor(
    private _api: ApiService
  , private spinner: NgxSpinnerService
  , private router: Router
  , private cookieService: CookieService
  , private dialog: MatDialog,
  ) {}

  checkLocationAccess() {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser.");
      return;
    }
  
    navigator.geolocation.getCurrentPosition(
      (position) => {
        
        console.log("Location access granted.");
        console.log(`Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`);
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 16;
        this.cookieService.set('latitude', this.lat);
        this.cookieService.set('longitude', this.lng);
      },
      (error) => {
        switch (error.code) {
          case 1: // PERMISSION_DENIED
            this.showPopup(
              "Location Permission Denied",
              "Please enable location access in your browser settings.",
              "PERMISSION_DENIED"
            );
            break;
          case 2: // POSITION_UNAVAILABLE
          this.showPopup(
              "Location Unavailable",
              "We could not retrieve your location. Please ensure location services are enabled on your device.",
              "POSITION_UNAVAILABLE"
            );
            break;
          case 3: // TIMEOUT
          this.showPopup(
              "Request Timed Out",
              "The request to retrieve your location timed out. Please try again.",
              "TIMEOUT"
            );
            break;
          default:
            this.showPopup(
              "Error",
              "An unknown error occurred while accessing your location.",
              "Error"
            );
        }
      }
    );
  }
  
  showPopup(title: string, message: string, case_type: string) {
    const dialogRef = this.dialog.open(BasicPopupComponent, {
      maxWidth: '600px'
      , data: {
        pageTitle: title,
        message: message,
        pageType: case_type
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        
      }
    });
  }
  
ngOnInit(): void {
  this.checkLocationAccess();
  this.deleteAllCookies();
  this.getDynamicForms();
  }

  // getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(position => {
  //       this.lat = position.coords.latitude;
  //       this.lng = position.coords.longitude;
  //       this.zoom = 16;
  //       this.cookieService.set('latitude', this.lat);
  //       this.cookieService.set('longitude', this.lng);
  //       console.log("position", position)
  //     });
  //   }else{
  //     console.log("User not allowed")
  //   }
  // }

  deleteAllCookies() {
    console.log('-------------');
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

getDynamicForms(v2token?) {
    let isV2Required;
    if(v2token){
      isV2Required = true;
    }
  this.spinner.show();
  const apiData = {
    page: 1,
    limit: 10,
    sortorder: "DESC",
    sortcolumn: "created",
    isV2CaptchaRequired: isV2Required
  };
  this._api.functionPOST('web/getWebActiveTemplatesList', apiData, v2token).subscribe((response) => {
    if(response['success'] && !response['data']['isV2CaptchaRequired']){
      this.isV2CaptchaRequired = false;
      this.dynamicFormList = response['data']['data'];
      
     } else {
      this.isV2CaptchaRequired = response['data']['isV2CaptchaRequired'];
    }
    this.spinner.hide();
  });
}

goToApp(event: any) {
  this.router.navigate(['user-submission-form/' + event + '']);
}

handleSuccess(captchaResponse: string): void {
  console.log('CAPTCHA Response:', captchaResponse);
  this.getDynamicForms(captchaResponse);
  // Use the response token for server-side validation
}

handleError(error: any): void {
  console.error('CAPTCHA Error:', error);
}

handleExpired(): void {
  console.warn('CAPTCHA Expired');
  // Optionally reset the CAPTCHA if needed
}

}

