import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-title',
  template: `
    <h5 [class]="to.className || 'text-center'">{{ to.label }}</h5>
  `,
  styles: [
    `
      h5 row {
        font-weight: 700;
        background: aqua;
        height: 40px;
      }
    `,
  ],
})
export class TitleComponent extends FieldType {}
