import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  initGtm() {
    const gtmId = environment.google_tag_id;
    if (!gtmId) {
      console.error('GTM ID is missing!');
      return;
    }

    // Add GTM <script>
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    document.head.appendChild(script);

    // Add GTM <noscript>
    // const noscript = document.createElement('noscript');
    // noscript.innerHTML = `
    //   <iframe
    //     src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
    //     height="0"
    //     width="0"
    //     style="display:none;visibility:hidden"
    //   ></iframe>`;
    // document.body.appendChild(noscript);
  }
}
