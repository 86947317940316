import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-custom-input',
  template: `
    <label *ngIf="to.label" class="custom-label" [innerHTML]="to.label + (to.required ? ' <span class=&quot;text-danger&quot;>*</span>' : '')"></label>
    <mat-form-field appearance="fill" class="custom-material-input">

      <input  *ngIf="!to.isDate"
        matInput
        [formControl]="formControl"
        [formlyAttributes]="field"
        [placeholder]="to.placeholder"
        [readonly]="to.readonly"
      />
      <div *ngIf="to.isDate">
        <input
          matInput
          [formControl]="formControl"
          [formlyAttributes]="field"
          [placeholder]="to.placeholder"
          [readonly]="to.readonly"
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <ng-container #fieldComponent></ng-container>
      <button mat-icon-button matSuffix *ngIf="to['tooltip']" [matTooltip]="to['tooltip']" tabindex="-1" (click)="$event.stopPropagation()">
        <mat-icon>info_outline</mat-icon>
      </button>
      <mat-error *ngIf="showError">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
    </mat-form-field>
  `,
  styles: [
    `
    .custom-material-input {
        width: 100%;
      }
      .custom-input-wrapper {
        display: flex;
        flex-direction: column;
      }
      .custom-label {
        margin-bottom: 4px;
      }
      .custom-input {
        margin-top: 8px;
      }
      ::ng-deep .mat-form-field-wrapper {
        margin-bottom: 0px !important;
      }
      ::ng-deep .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
        height: 0em;
      }
    `,
  ],
})
export class FormlyCustomInputComponent extends FieldType {}
