import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-custom-multi-select',
  template: `
  <div class="form-group">
      <label [innerHTML]="to.label" [ngClass]="{ 'required-label': to.required }"></label>
    </div>
    <mat-form-field appearance="fill" class="custom-multi-select">
      <mat-select
        [formControl]="formControl"
        [formlyAttributes]="field"
        [multiple]="to.multiple"
        [placeholder]="to.placeholder"
        className="to.className"
        (selectionChange)="to.selectionChange && to.selectionChange(field, $event)">
        <mat-option
          *ngFor="let option of to.options"
          [value]="option.value"
        >
          {{ option.label }}
        </mat-option>
      </mat-select>
      <ng-container #fieldComponent></ng-container>
      <button mat-icon-button matSuffix *ngIf="to['tooltip']" [matTooltip]="to['tooltip']" tabindex="-1" (click)="$event.stopPropagation()">
        <mat-icon>info_outline</mat-icon>
      </button>
      <mat-error *ngIf="showError">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
    </mat-form-field>
  `,
  styles: [
    `
    .custom-multi-select {
        width: 100%;
      }
    .required-label::after {
      content: '*';
      color: red;
      margin-left: 4px;
    }
    .mt-1{
      margin-top: 15px !important;
    }
    ::ng-deep .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
        height: 0em;
      }
    `,
  ],
})
export class FormlyCustomMultiSelectComponent extends FieldType {}
