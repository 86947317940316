import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldType } from '@ngx-formly/core';
import { FormlyDynamicModalComponent } from '../popups/formly-dynamic-modal/formly-dynamic-modal.component';


@Component({
  selector: 'app-formly-custom-table',
  template: `
    <table class="table table-bordered">
      <thead>
        <tr>
          <th *ngFor="let col of to['columns']">{{ col.label }}</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of to['data'];let i = index">
          <!-- <td *ngFor="let col of to['columns']">{{ row[col.key] }}</td> -->
          <td *ngFor="let col of to['columns']">
            {{ row[col.key] || '-' }}
          </td>
          <td>
            <button class="blue_button mr-3" mat-raised-button (click)="deleteRow(i, to['data'])">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  `,
})
export class FormlyCustomTable extends FieldType {
  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef) {
    super();
  }

  deleteRow(index, row: any): void {
    console.log('---------delete', row);

    const currentData = row;
    currentData.splice(index, 1);
    this.model.dynamicLicData = currentData
    
    // if (Array.isArray(this.model.dynamicLicData) && this.model.dynamicLicData.length > 0) {
    //   // Use splice to remove the row at the given index
    //   this.model.dynamicLicData.splice(index, 1);
  
    //   // Trigger change detection if needed to update the view
    //   this.cdr.detectChanges();
    // } else {
    //   console.warn('No data to delete or invalid index.');
    // }
  }
}
