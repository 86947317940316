import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyDynamicModalComponent } from '../shared/popups/formly-dynamic-modal/formly-dynamic-modal.component';
import { Router } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';


type ExpressionFunction = (model: any, formState: any, field?: FormlyFieldConfig) => boolean;

interface FormlyExpressions {
  [key: string]: string | boolean | ExpressionFunction;
}

@Component({
  selector: 'app-dynamic-application-demo',
  templateUrl: './dynamic-application-demo.component.html',
  styleUrls: ['./dynamic-application-demo.component.css']
})
export class DynamicApplicationDemoComponent implements OnInit {
  indJsonForm: any;
  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private _api: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.fetch((data) => this.model = data);
    this.assignClickEvent();
  }

  locationControl = new FormControl();
  locationList: string[] = ['Add a Public Location', 'Add Private Location', 'Multi-Location'];
  keywordControl = new FormControl();
  keywordList: string[] = ['Bike', 'Skates', 'Computer'];
  requirementControl = new FormControl();
  requirementsList: string[] = ['No Requirements', 'ID', 'Insurance', 'Background Check', 'Other'];
  value = 'My Awesome Title';
  step = 0;
  selectedshare = 'Stay';
  requirements: FormGroup;
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onFileComplete(data: any) {
    console.log(data); 
  }
  
  form = new FormGroup({});
  // model: MyModel = {
  //   dynamicData: []
  // };

  model: {
    dynamicData?: any[], // Optional dynamicData
    status?: 'Pending'
  } = {};
  options: FormlyFormOptions = {};

  fields: FormlyFieldConfig[] = [
    {
      "type": "tabs",
      "fieldGroup": [
        {
          "templateOptions": {
            "label": "Business"
          },
          "fieldGroup": [
            {
              "type": "section",
              "templateOptions": {
                "label": "General Business Information",
                "className": "custom-section-header"
              },
              "fieldGroupClassName": "custom-section",
              "fieldGroup": [
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "templateOptions": {
                        
                      },
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                            "key": "legalBusinessName",
                            "type": "custom-input",
                            "templateOptions": {
                              "label": "What is the legal business name?",
                              "labelHtml": true,
                              "required": true,
                              "tooltip": "Please enter Business Name.",
                              "placeholder": "",
                              "disabled": false,
                            }
                          },
                          {
                            "key": "employerOrTaxId",
                            "type": "phone",
                            "templateOptions": {
                              "label": "Employer or Tax Identification Number (EIN or TIN)",
                              "tooltip": "Please enter Business Name.",
                              "type": "tel",
                              "required": true,
                              "maxLength": 10,
                              "attributes": {
                                "inputmode": "numeric"
                              },
                              "mask": "00-0000000",
                              "maskOptions": {
                                "showMaskTyped": true,
                                "guide": true
                              },
                              "placeholder": "",
                              "disabled": false
                            },
                            "validation": {
                              "messages": {
                                "required": "EIN or TIN Number is required."
                              }
                            }
                          },
                          {
                            "key": "natureOfBusiness",
                            "type": "custom-input",
                            "templateOptions": {
                              "label": "What is the nature of the business?",
                              "tooltip": "Please enter fictitious business name or DBA.",
                              "required": true,
                              "placeholder": "",
                              "disabled": false
                            }
                          }
                      ]
                    },
                    {
                      "type": "column",
                      "templateOptions": {
                        
                      },
                        "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "fictitiousBusinessName",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "What is the fictitious business name or DBA?",
                            "tooltip": "Please enter fictitious business name or DBA.",
                            "required": false,
                            "placeholder": "",
                            "disabled": false
                          }
                        },
                        {
                          "key": "registeredCountry",
                          "type": "custom-multi-select",
                          "templateOptions": {
                            "label": "What is the registered country?",
                            "tooltip": "Please enter fictitious business name or DBA.",
                            "required": true,
                            "multiple": false,
                            "options": [],
                            "changeFunction": "onregisteredCountryChange",
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "mt-1"
                        },
                        {
                          "key": "naicsCode",
                          "type": "custom-multi-select",
                          "templateOptions": {
                            "label": "NAICS code",
                            "tooltip": "Please enter fictitious business name or DBA.",
                            "required": true,
                            "multiple": false,
                            "options": [],
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "templateOptions": {
                        
                      },
                      "className": "col-md-4 mt-1",
                      "fieldGroup": [
                        {
                          "key": "formationType",
                          "type": "custom-multi-select",
                          "templateOptions": {
                            "label": "What is the formation type for the business?",
                            "tooltip": "Select business.",
                            "required": true,
                            "multiple": false,
                            "options": [
                              {
                                "value": "soleProprietor",
                                "label": "Sole Proprietor"
                              },
                              {
                                "value": "limitedLiabilityCompany",
                                "label": "Limited Liability Company"
                              },
                              {
                                "value": "cooperative",
                                "label": "Cooperative"
                              },
                              {
                                "value": "corporation",
                                "label": "Corporation"
                              },
                              {
                                "value": "partnership",
                                "label": "Partnership"
                              },
                              {
                                "value": "notForProfit",
                                "label": "Not-for-profit"
                              },
                              {
                                "value": "sCorporation",
                                "label": "S-Corporation"
                              },
                              {
                                "value": "independentContractor",
                                "label": "Independent Contractor"
                              },
                              {
                                "value": "employeeOwnedEntities",
                                "label": "Employee-Owned Entities"
                              }
                            ],
                            "disabled": false
                          }
                        },
                        {
                          "key": "registeredStateOrProvince",
                          "type": "custom-multi-select",
                          "templateOptions": {
                            "label": "What is the registered state or province?",
                            "tooltip": "Select registered state or province",
                            "required": true,
                            "multiple": false,
                            "options": [],
                            "placeholder": "",
                            "disabled": false
                          }
                        },
                        {
                          "key": "ownershipType",
                          "type": "custom-multi-select",
                          "templateOptions": {
                            "label": "Is the business publicly or privately owned?",
                            "tooltip": "Select business type",
                            "required": true,
                            "multiple": false,
                            "options": [
                              {
                                "value": "public",
                                "label": "Public"
                              },
                              {
                                "value": "private",
                                "label": "Private"
                              }
                            ],
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    }
                   
                  ]
                } 
              ]
            },
            {
              "type": "section",
              "templateOptions": {
                "label": "Business Contact Information",
                "className": "custom-section-header"
              },
              "fieldGroupClassName": "custom-section",
              "fieldGroup": [
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "businessPhoneNumber",
                          "type": "phone",
                          "templateOptions": {
                            "label": "Business Phone Number",
                            "required": true,
                            "mask": "(000) 000-0000",
                            "tooltip": "Please enter Business Phone Number.",
                            "placeholder": "",
                            "disabled": false,
                            "type": "tel"
                          },
                          "validation": {
                            "messages": {
                              "required": "Business Phone Number is required."
                            }
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "businessWebsiteAddress",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Business Website Address",
                            "type": "url",
                            "required": true,
                            "tooltip": "Please enter the registered business website address.",
                            "pattern": "^(https?:\\/\\/)?([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}(\\/.*)?$",
                            "placeholder": "",
                            "disabled": false
                          },
                          "validation": {
                            "messages": {
                              "required": "Business Website Address is required.",
                              "pattern": "Please enter a valid website URL."
                            }
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "primaryContactPerson",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Primary Contact Person",
                            "type": "text",
                            "required": true,
                            "minLength": 3,
                            "maxLength": 50,
                            "placeholder": "",
                            "disabled": false
                          },
                          "validation": {
                            "messages": {
                              "minLength": "Primary Contact Person must be at least 3 characters long.",
                              "maxLength": "Primary Contact Person cannot exceed 50 characters."
                            }
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "primaryContactPersonEmailAddress",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Primary Contact Person Email Address",
                            "type": "email",
                            "required": true,
                            "pattern": "^\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,3}$",
                            "patternMessage": "Please enter a valid email address.",
                            "placeholder": "",
                            "disabled": false
                          },
                          "validation": {
                            "messages": {
                              "pattern": "Please enter a valid email address."
                            }
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "primaryContactPersonPhoneNumber",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Primary Contact Person Phone Number",
                            "type": "tel",
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "legalBusinessAddressLine1",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Legal Business Address Line 1",
                            "type": "text",
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "legalBusinessAddressLine2",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Legal Business Address Line 2",
                            "type": "text",
                            "required": false,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "legalBusinessCity",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Legal Business City",
                            "type": "text",
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "legalBusinessState",
                          "type": "custom-multi-select",
                          "templateOptions": {
                            "label": "Legal Business State",
                            "options": [
                              {
                                "label": "State 1",
                                "value": "state1"
                              },
                              {
                                "label": "State 2",
                                "value": "state2"
                              }
                            ],
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "legalBusinessZipCode",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Legal Business Zip Code",
                            "type": "text",
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          }
                          
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "legalBusinessCountry",
                          "type": "custom-multi-select",
                          "templateOptions": {
                            "label": "Legal Business Country",
                            "options": [
                              {
                                "label": "United States",
                                "value": "us"
                              },
                              {
                                "label": "Canada",
                                "value": "ca"
                              }
                            ],
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "mailingAddress",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Mailing Address, (if different than above)",
                            "type": "text",
                            "required": false,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "mailingStreetAddressLine1",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Mailing Street Address Line 1",
                            "type": "text",
                            "required": false,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "mailingStreetAddressLine2",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Mailing Street Address Line 2",
                            "type": "text",
                            "required": false,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "mailingCity",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Mailing City",
                            "type": "text",
                            "required": false,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "mailingState",
                          "type": "custom-multi-select",
                          "templateOptions": {
                            "label": "Mailing State",
                            "options": [],
                            "required": false,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "mailingZip",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Mailing Zip",
                            "type": "text",
                            "required": false,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "mailingCountry",
                          "type": "custom-multi-select",
                          "templateOptions": {
                            "label": "Mailing Country",
                            "options": [],
                            "required": false,
                            "changeFunction": "onMailingCountryChange",
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "type": "section",
              "templateOptions": {
                "label": "Business Details",
                "className": "custom-section-header"
              },
              "fieldGroupClassName": "custom-section",
              "fieldGroup": [
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "purposeofopeningaccount",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "What is the Purpose of Opening This Account",
                            "type": "text",
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          },
                          "validation": {
                            "messages": {
                              "required": "Purpose of Opening This Account is required."
                            }
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "sourceofaccountfunds",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "What is the Source of Account Funds",
                            "type": "text",
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "beneficialOwners",
                          "type": "custom-radio",
                          "templateOptions": {
                            "label": "Does the business have any Beneficial Owners (defined as any person with at least 25% ownership)?",
                            "options": [
                              {
                                "label": "Yes",
                                "value": "yes"
                              },
                              {
                                "label": "No",
                                "value": "no"
                              }
                            ],
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "custom-radio"
                        },
                        {
                          "template": "<hr>",
                          "type": "html"
                        },
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "businesscontrolperson",
                          "type": "custom-radio",
                          "templateOptions": {
                            "label": "Does the business have any Control Persons (defined as any individual having significant responsibility for controlling, managing, or directing the legal business entity) ?",
                            "options": [
                              {
                                "label": "Yes",
                                "value": "yes"
                              },
                              {
                                "label": "No",
                                "value": "no"
                              }
                            ],
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "custom-radio"
                        },
                        {
                          "template": "<hr>",
                          "type": "html"
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "numberofemployees",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "How many employees does the business have?",
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "businesslocation",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "How many locations does the business have?",
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "authorizedsinger",
                          "type": "custom-radio",
                          "templateOptions": {
                            "label": "Is any authorized signer, grantor or fiduciary a Politically Exposed Persons (PEPs)? (any Senior Foreign Political Figure, foreign government official or a foreign military official or a foreign government owned business entity, or related to / or a close associate of a PEP)",
                            "options": [
                              {
                                "label": "Yes",
                                "value": "yes"
                              },
                              {
                                "label": "No",
                                "value": "no"
                              }
                            ],
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "custom-radio"
                        },
                        {
                          "template": "<hr>",
                          "type": "html"
                        },
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "businessengage",
                          "type": "custom-radio",
                          "templateOptions": {
                            "label": "Does your business engage in, receive, or will be receiving income from Internet Gambling Business? ",
                            "options": [
                              {
                                "label": "Yes",
                                "value": "yes"
                              },
                              {
                                "label": "No",
                                "value": "no"
                              }
                            ],
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "custom-radio"
                        },
                        {
                          "template": "<hr class='mt-twofour'>",
                          "type": "html"
                        },
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "businessreceiveanyincome",
                          "type": "custom-radio",
                          "templateOptions": {
                            "label": "Does your business receive any income or offers any services that deal with convertible virtual currency, whether directly or indirectly? ",
                            "options": [
                              {
                                "label": "Yes",
                                "value": "yes"
                              },
                              {
                                "label": "No",
                                "value": "no"
                              }
                            ],
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "custom-radio"
                        },
                        {
                          "template": "<hr class='mt-twofour'>",
                          "type": "html"
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "isBusinessCannabisRelated",
                          "type": "custom-radio",
                          "templateOptions": {
                            "label": "Is the business cannabis-related?",
                            "required": true,
                            "options": [
                              {
                                "label": "Yes",
                                "value": "yes"
                              },
                              {
                                "label": "No",
                                "value": "no"
                              }
                            ],
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "custom-radio"
                        },
                        {
                          "template": "<hr class='mt-three'>",
                          "type": "html"
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "activeCannabisLicense",
                          "type": "custom-radio",
                          "templateOptions": {
                            "label": "Does the business have an active cannabis license(s)?",
                            "required": false,
                            "options": [
                              {
                                "label": "Yes",
                                "value": "yes"
                              },
                              {
                                "label": "No",
                                "value": "no"
                              }
                            ],
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "custom-radio"
                        },
                        {
                          "template": "<hr class='mt-three'>",
                          "type": "html"
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "businessNeed",
                          "type": "multi-checkbox",
                          "templateOptions": {
                            "label": "What type of account does the business need?",
                            "options": [
                              {
                                "label": "Savings",
                                "value": "Savings"
                              },
                              {
                                "label": "Checking",
                                "value": "Checking"
                              },
                              {
                                "label": "Money Market",
                                "value": "Money Market"
                              },
                              {
                                "label": "Lending",
                                "value": "Lending"
                              },
                              {
                                "label": "ACH",
                                "value": "ACH"
                              },
                              {
                                "label": "Debit Card",
                                "value": "Debit Card"
                              },
                              {
                                "label": "Other",
                                "value": "other"
                              }
                            ],
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          }
                        },
                        {
                          "template": "<hr>",
                          "type": "html"
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "templateOptions": {
                    "label": "Cannabis Licenses",
                    "className": "custom-section-header",
                    "hidden": true
                  },
                  "fieldGroupClassName": "custom-section",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-12",
                      "fieldGroup": [
                        {
                          "key": "searchLicenceButton",
                          "type": "custom-button",
                          "templateOptions": {
                            "text": "Add Licence",
                            "onClick": "openRightSlideModalLic",
                            "label": "",
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "mb-4 float-end"
                        },
                        {
                          "template": "<hr>",
                          "type": "html"
                        }
                      ]
                    },
                    {
                      "className": "col-md-12",
                      "key": "dynamicTable",
                      "type": "table",
                      "templateOptions": {
                        "columns": [
                          {
                            "key": "bp_license_no",
                            "label": "License Number"
                          },
                          {
                            "key": "bp_license_state_bt_region_name",
                            "label": "State Issued"
                          },
                          {
                            "key": "bp_license_type_name",
                            "label": "Type"
                          },
                          {
                            "key": "expiration_date",
                            "label": "Exp"
                          }
                        ],
                        "data": [],
                        "label": "",
                        "placeholder": "",
                        "disabled": false
                      }
                      
                    }
                  ],
                  "hideExpression": "!model.activeCannabisLicense || model.activeCannabisLicense === 'no'"          
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-8",
                      "fieldGroup": [
                        {
                          "key": "cannabisBusinessType",
                          "type": "multi-checkbox",
                          "templateOptions": {
                            "label": "Cannabis Business Type",
                            "options": [
                              {
                                "label": "Retail/Dispensary",
                                "value": "Retail/Dispensary"
                              },
                              {
                                "label": "Cultivation",
                                "value": "Cultivation"
                              },
                              {
                                "label": "Processor",
                                "value": "Processor"
                              },
                              {
                                "label": "Microbusiness",
                                "value": "Microbusiness"
                              },
                              {
                                "label": "Distribution",
                                "value": "distribution"
                              },
                              {
                                "label": "Manufacturer",
                                "value": "manufacturer"
                              },
                              {
                                "label": "Delivery",
                                "value": "delivery"
                              },
                              {
                                "label": "Other",
                                "value": "other"
                              }
                            ],
                            "required": true,
                            "placeholder": "",
                            "disabled": false,
                            "hidden": true
                          },
                          "hideExpression": "!model.isBusinessCannabisRelated || model.isBusinessCannabisRelated === 'no'"
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "otherCannabisBusinessType",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Please specify other business type:",
                            "placeholder": "Enter other business type",
                            "required": true,
                            "disabled": false,
                            "hidden": true
                          },
                          "hideExpression": "!model.cannabisBusinessType || !model.cannabisBusinessType.includes('other')"
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "otherBusinessNeed",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Please specify other business need:",
                            "placeholder": "Enter other business need",
                            "required": true,
                            "disabled": false,
                            "hidden": true
                          },
                          "hideExpression": "!model.businessNeed || !model.businessNeed.includes('other')"
                        },
                        {
                          "key": "currentPayrollProvider",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Who is the current payroll provider for the business?",
                            "type": "text",
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "hasAtm",
                          "type": "custom-radio",
                          "templateOptions": {
                            "label": "Is there an ATM at any of the company’s locations?",
                            "required": true,
                            "options": [
                              {
                                "label": "Yes",
                                "value": "yes"
                              },
                              {
                                "label": "No",
                                "value": "no"
                              }
                            ],
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "custom-radio"
                        },
                        {
                          "template": "<hr class='mt-twofour'>",
                          "type": "html"
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "businessOperateOutside",
                          "type": "custom-radio",
                          "templateOptions": {
                            "label": "Does the business operate outside the registered state/province?",
                            "required": true,
                            "options": [
                              {
                                "label": "Yes",
                                "value": "yes"
                              },
                              {
                                "label": "No",
                                "value": "no"
                              }
                            ],
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "custom-radio"
                        },
                        {
                          "template": "<hr class='mt-twofour'>",
                          "type": "html"
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "section",
                  "templateOptions": {
                    "label": "Complete all ATM Questions",
                    "className": "custom-section-header",
                    "hidden": true
                  },
                  "fieldGroupClassName": "custom-section",
                  "fieldGroup": [
                    {
                      "type": "row",
                      "fieldGroup": [
                        {
                          "type": "column",
                          "className": "col-md-4",
                          "fieldGroup": [
                            {
                              "key": "numberOfAtms",
                              "type": "custom-input",
                              "templateOptions": {
                                "label": "Number of ATMs",
                                "required": true,
                                "placeholder": "",
                                "disabled": false,
                                "hidden": true
                              },
                              "hideExpression": "!model.hasAtm || model.hasAtm === 'no'"
                            }
                          ]
                        },
                        {
                          "type": "column",
                          "className": "col-md-4",
                          "fieldGroup": [
                            {
                              "key": "legalNameOfCompanyOwningAtm",
                              "type": "custom-input",
                              "templateOptions": {
                                "label": "Legal Name of Company(s) That Own the ATM(s)",
                                "required": true,
                                "placeholder": "",
                                "disabled": false,
                                "hidden": true
                              },
                              "hideExpression": "!model.hasAtm || model.hasAtm === 'no'"
                            }
                          ]
                        },
                        {
                          "type": "column",
                          "className": "col-md-4",
                          "fieldGroup": [
                            {
                              "key": "atmCashReplenishmentFrequency",
                              "type": "custom-input",
                              "templateOptions": {
                                "label": "Frequency of ATM Cash Replenishment",
                                "required": true,
                                "placeholder": "",
                                "disabled": false,
                                "hidden": true
                              },
                              "hideExpression": "!model.hasAtm || model.hasAtm === 'no'"
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "type": "row",
                      "fieldGroup": [
                        {
                          "type": "column",
                          "className": "col-md-4",
                          "fieldGroup": [
                            {
                              "key": "methodOfReplenishment",
                              "type": "custom-radio",
                              "templateOptions": {
                                "label": "Method of Replenishment",
                                "required": true,
                                "options": [
                                  {
                                    "label": "Internal",
                                    "value": "internal"
                                  },
                                  {
                                    "label": "3rd-Party",
                                    "value": "third-party"
                                  }
                                ],
                                "placeholder": "",
                                "disabled": false,
                                "hidden": true
                              },
                              "hideExpression": "!model.hasAtm || model.hasAtm === 'no'",
                              "className": "custom-radio"
                            },
                            {
                              "key": "thirdPartyName",
                              "type": "custom-input",
                              "templateOptions": {
                                "label": "Name of 3rd Party",
                                "required": true,
                                "placeholder": "",
                                "disabled": false,
                                "hidden": true
                              },
                              "hideExpression": "!model.methodOfReplenishment || model.methodOfReplenishment !== 'third-party'"
                            }
                          ]
                        },
                        {
                          "type": "column",
                          "className": "col-md-4",
                          "fieldGroup": [
                            {
                              "key": "monthlyAtmTransactions",
                              "type": "phone",
                              "templateOptions": {
                                "type": "custom-input",
                                "label": "Monthly ATM Transactions (#)",
                                "required": true,
                                "attributes": {
                                  "inputmode": "numeric"
                                },
                                "mask": "separator.2",
                                "maskOptions": {
                                  "showMaskTyped": true,
                                  "guide": false
                                },
                                "tooltip": "Please enter the floating number.",
                                "placeholder": "",
                                "disabled": false,
                                "hidden": true
                              },
                              "hideExpression": "!model.hasAtm || model.hasAtm === 'no'",
                              "validation": {
                                "messages": {
                                  "required": "This field is required.",
                                  "pattern": "Please enter a number in the format 00.00.",
                                  "min": "The number must be greater than or equal to 00.00.",
                                  "max": "The number must be less than or equal to 99.99."
                                }
                              }
                            }
                          ]
                        },
                        {
                          "type": "column",
                          "className": "col-md-4",
                          "fieldGroup": [
                            {
                              "key": "monthlyAtmVolume",
                              "type": "custom-input",
                              "templateOptions": {
                                "label": "Monthly ATM Volume ($)",
                                "type": "test",
                                "required": true,
                                "appCurrencyFormat": true,
                                "placeholder": "",
                                "disabled": false,
                                "hidden": true
                              },
                              "hideExpression": "!model.hasAtm || model.hasAtm === 'no'"
                            }
                          ]
                        }
                      ]
                    }
                  ],
                  "hideExpression": "!model.hasAtm || model.hasAtm === 'no'"
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "isElectronicPayments",
                          "type": "custom-radio",
                          "templateOptions": {
                            "label": "Does the business accept electronic payments?",
                            "required": true,
                            "options": [
                              {
                                "label": "Yes",
                                "value": "yes"
                              },
                              {
                                "label": "No",
                                "value": "no"
                              }
                            ],
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "custom-radio"
                        },
                        {
                          "template": "<hr class='mt-twofour'>",
                          "type": "html"
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "electronicPaymentProvider",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Who is the Electronic Payment Provider?",
                            "required": true,
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "anyBankAccount",
                          "type": "custom-radio",
                          "templateOptions": {
                            "label": "Does the business have any bank accounts at other financial institutions?",
                            "required": true,
                            "options": [
                              {
                                "label": "Yes",
                                "value": "yes"
                              },
                              {
                                "label": "No",
                                "value": "no"
                              }
                            ],
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "custom-radio"
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "currentAccount",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "What is the name of the financial institution(s) you currently have accounts with",
                            "required": true,
                            "placeholder": "",
                            "disabled": false,
                            "hidden": true
                          },
                          "hideExpression": "!model.anyBankAccount || model.anyBankAccount === 'no'"
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": []
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": []
                    
                    }
                  ]
                }
              ]
            },
            {
              "type": "section",
              "templateOptions": {
                "label": "Business Expected Activity",
                "span": "Provide your monthly estimated amount of usage for the transaction methods listed below. If you do not typically use one of the transaction methods, but may in the future, we recommend entering an estimated amount. If you will not use one of the transaction methods in the future, enter 0.",
                "className": "custom-section-header"
              },
              "fieldGroupClassName": "custom-section",
              "fieldGroup": [
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "totalSales",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Total Sales",
                            
                            "required": true,
                            "placeholder": "Enter amount",
                            "attributes": {
                              "inputmode": "numeric"
                            },
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "averageBalance",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Average Balance",
                            
                            "required": true,
                            "placeholder": "Enter amount",
                            "attributes": {
                              "inputmode": "numeric"
                            },
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "cashDeposits",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Cash Deposits",
                            
                            "required": true,
                            "placeholder": "Enter amount",
                            "attributes": {
                              "inputmode": "numeric"
                            },
                            "disabled": false
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "cashWithdrawals",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Cash Withdrawals",
                            
                            "required": true,
                            "placeholder": "Enter amount",
                            "attributes": {
                              "inputmode": "numeric"
                            },
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "checkDeposits",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Check Deposits",
                            
                            "required": true,
                            "placeholder": "Enter amount",
                            "attributes": {
                              "inputmode": "numeric"
                            },
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "checkWithdrawals",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Check Withdrawals",
                            
                            "required": true,
                            "placeholder": "Enter amount",
                            "attributes": {
                              "inputmode": "numeric"
                            },
                            "disabled": false
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "incomingACH",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Incoming ACH",
                            
                            "required": true,
                            "placeholder": "Enter amount",
                            "attributes": {
                              "inputmode": "numeric"
                            },
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "outgoingACH",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Outgoing ACH",
                            
                            "required": true,
                            "placeholder": "Enter amount",
                            "attributes": {
                              "inputmode": "numeric"
                            },
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "incomingWireTransfers",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Incoming Wire Transfers",
                            
                            "required": true,
                            "placeholder": "Enter amount",
                            "attributes": {
                              "inputmode": "numeric"
                            },
                            "disabled": false
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "outgoingWireTransfers",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Outgoing Wire Transfers",
                            
                            "required": true,
                            "placeholder": "Enter amount",
                            "attributes": {
                              "inputmode": "numeric"
                            },
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "incomingInternationalWireTransfers",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Incoming International Wire Transfers",
                            
                            "required": true,
                            "placeholder": "Enter amount",
                            "attributes": {
                              "inputmode": "numeric"
                            },
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "outgoingInternationalWireTransfers",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Outgoing International Wire Transfers",
                            
                            "required": true,
                            "placeholder": "Enter amount",
                            "attributes": {
                              "inputmode": "numeric"
                            },
                            "disabled": false
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "acceptMerchantSettlements",
                          "type": "custom-radio",
                          "templateOptions": {
                            "label": "Does the business accept merchant settlements?",
                            "required": true,
                            "options": [
                              {
                                "value": "Yes",
                                "label": "Yes"
                              },
                              {
                                "value": "No",
                                "label": "No"
                              }
                            ],
                            "placeholder": "",
                            "disabled": false
                          },
                          "className": "custom-radio"
                        }
                      ] 
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "paymentTypesAccepted",
                          "type": "multi-checkbox",
                          "templateOptions": {
                            "label": "What Types of Payments Does the Business Accept?",
                            "required": true,
                            "options": [
                              {
                                "value": "Debit Card",
                                "label": "Debit Card"
                              },
                              {
                                "value": "Credit Card",
                                "label": "Credit Card"
                              },
                              {
                                "value": "Cash",
                                "label": "Cash"
                              },
                              {
                                "value": "Virtual Currency",
                                "label": "Virtual Currency"
                              },
                              {
                                "value": "Gift Card",
                                "label": "Gift Card"
                              },
                              {
                                "value": "Check",
                                "label": "Check"
                              },
                              {
                                "value": "Wire Transfers",
                                "label": "Wire Transfers"
                              },
                              {
                                "value": "ACH",
                                "label": "ACH"
                              },
                              {
                                "value": "other",
                                "label": "Other"
                              }
                            ],
                            "placeholder": "",
                            "disabled": false
                          }
                        }
                      ]
                    },
                    {
                      "type": "column",
                      "className": "col-md-4",
                      "fieldGroup": [
                        {
                          "key": "otherpaymentTypesAccepted",
                          "type": "custom-input",
                          "templateOptions": {
                            "label": "Please specify other business need:",
                            "placeholder": "Enter other business need",
                            "required": true,
                            "disabled": false,
                            "hidden": true
                          },
                          "hideExpression": "!model.paymentTypesAccepted || !model.paymentTypesAccepted.includes('other')"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "templateOptions": {
            "label": "Individual Details"
          },
          "fieldGroup": [
            {
              "type": "section",
              "templateOptions": {
                "label": "Beneficial Owner and Controlling Managers",
                "className": "custom-section-header"
              },
              "fieldGroupClassName": "custom-section",
              "fieldGroup": [
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "templateOptions": {
                        
                      },
                      "className": "col-md-12 mb-4",
                      "fieldGroup": [
                        {
                          "key": "addIndbtn",
                          "type": "custom-button",
                          "templateOptions": {
                            "text": "+ Add Individual",
                            "onClick": "openRightSlideModalInd",
                            "disabled": false
                          },
                          "className": "float-end"
                        }
                      ]
                    },
                    
                   
                  ]
                },

                {
                  "className": "col-md-12",
                  "key": "individualTable",
                  "type": "individual-table",
                  "templateOptions": {
                    "columns": [
                      {
                        "key": "firstName",
                        "label": "First Name",
                        "type": "input",
                        "editable": true
                      },
                      {
                        "key": "middleInitial",
                        "label": "MI",
                        "type": "input",
                        "editable": true
                      },
                      {
                        "key": "lastName",
                        "label": "Last Name",
                        "type": "input",
                        "editable": true
                      },
                      {
                        "key": "ownershipPercentage",
                        "label": "% Ownership",
                        "type": "input",
                        "editable": true
                      },
                      {
                        "key": "jobTitle",
                        "label": "Business Title",
                        "type": "input",
                        "editable": true
                      },
                      {
                        "key": "authorizedSigner",
                        "label": "Authorized Signer",
                        "type": "input",
                        "editable": true
                      },
                      {
                        "key": "jobTitle",
                        "label": "Beneficiary Owner",
                        "type": "input",
                        "editable": true
                      },
                      {
                        "key": "jobTitle",
                        "label": "Controlling Manager",
                        "type": "input",
                        "editable": true
                      }
                    ],
                    "data": []
                  }
                }

              ]
            }
          ]
        },
        {
          "templateOptions": {
            "label": "Documents"
          },
          "fieldGroup": [
            {
              "type": "section",
              "templateOptions": {
                "label": "Document Checklist",
                "className": "custom-section-header"
              },
              "fieldGroupClassName": "custom-section",
              "fieldGroup": [
                {
                  "type": "custom-accordion",
                  "fieldGroup": [
                    
                    {
                      "key": "businessIncomeTaxRetuns",
                      "templateOptions": {
                        "title": "Business Income Tax Returns",
                        "expanded": false,
                        "description": "",
                      },
                      "fieldGroup": [
                        {
                          "type": 'label-value',
                          "templateOptions": {
                            "label": 'Upload Status',
                            "value": 'Uploaded',
                            "status": true
                          }
                        },
                        {
                          "type": 'label-value',
                          "templateOptions": {
                            "label": 'Comments',
                            "value": 'This is test comments.',
                          },
                        },
                        {
                          "type": 'label-value',
                          "templateOptions": {
                            "label": 'Updated Date',
                            "value": 'Oct 01, 2024 11:40 AM',
                          },
                        },
                        {
                          "type": "row",
                          "fieldGroup": [
                            {
                              "type": "column",
                              "templateOptions": {
                                
                              },
                              "className": "col-md-12 mb-4",
                              "fieldGroup": [
                                {
                                  "key": "uploadDocBtn",
                                  "type": "custom-button",
                                  "templateOptions": {
                                    "text": "Upload",
                                    "onClick": "openRightSlideModalDoc",
                                    "disabled": false
                                  },
                                  "className": "float-end"
                                },
                                {
                                  "key": "notApplicableDocBtn",
                                  "type": "custom-button",
                                  "templateOptions": {
                                    "text": "Not Applicable",
                                    "onClick": "openRightSlideModalDocNotApplicable",
                                    "disabled": false
                                  },
                                  "className": "float-end me-4"
                                }
                              ]
                            }
                          ]
                        },

                        {
                          "className": "col-md-12",
                          "key": "docTable",
                          "type": "datatable",
                          "templateOptions": {
                            "columns": [
                              {
                                "key": "attach_name",
                                "label": "File"
                              },
                              {
                                "key": "attach_comment",
                                "label": "Comments"
                              },
                              {
                                "key": "updated",
                                "label": "Updated Date"
                              }
                            ],
                            "data": []
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
            
          ]
        }
      ]

    }
    
  ]

  ngOnInit(): void {
    this.bindFunctionsToTemplateOptions(this.fields);
    // this.fields = this.bindHideExpressions(this.fields);
    this.fields = this.bindExpressionProperties(this.fields);
    this.getCountryList();

    this.indJsonForm = [
      {
        "fieldGroup": [
          {
            "type": "section",
            "templateOptions": {
              "label": "Beneficial Owner",
              "className": "custom-section-header"
            },
            "fieldGroupClassName": "custom-section",
            "fieldGroup": [
              {
                "type": "row",
                "fieldGroup": [
                  {
                    "type": "column",
                    "className": "col-md-6 custom-tooltip-class",
                    "fieldGroup": [
                      {
                        "key": "firstName",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "First Name",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "First Name is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "middleInitial",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Middle Initial",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Middle Initial is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "lastName",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Last Name",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Last Name is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 custom-tooltip-class",
                    "fieldGroup": [
                      {
                        "key": "suffix",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Suffix"
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "responsibility",
                        "type": "custom-radio",
                        "templateOptions": {
                          "label": "Do you have significant responsibility for controlling, managing, or directing the legal business entity listed in this application?",
                          "required": true,
                          "options": [
                            {
                              "value": "Yes",
                              "label": "Yes"
                            },
                            {
                              "value": "No",
                              "label": "No"
                            }
                          ]
                        },
                        "validation": {
                          "messages": {
                            "required": "Significant responsibility is required."
                          }
                        },
                        "className": "custom-radio"
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "ownership25Percent",
                        "type": "custom-radio",
                        "templateOptions": {
                          "label": "Do you own 25% or more of the business listed in this application?",
                          "required": true,
                          "options": [
                            {
                              "value": "Yes",
                              "label": "Yes"
                            },
                            {
                              "value": "No",
                              "label": "No"
                            }
                          ]
                        },
                        "validation": {
                          "messages": {
                            "required": "Business listed in this application is required."
                          }
                        },
                        "className": "custom-radio"
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 custom-tooltip-class mt-4",
                    "fieldGroup": [
                      {
                        "key": "accountSigner",
                        "type": "custom-radio",
                        "templateOptions": {
                          "label": "Account Signer?",
                          "required": true,
                          "options": [
                            {
                              "value": "yes",
                              "label": "Yes"
                            },
                            {
                              "value": "no",
                              "label": "No"
                            }
                          ]
                        },
                        "validation": {
                          "messages": {
                            "required": "Account Signer is required."
                          }
                        },
                        "className": "custom-radio"
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 mt-4",
                    "fieldGroup": [
                      {
                        "key": "ownershipPercentage",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "% Ownership of the Business",
                          
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "% Ownership of the Business is required."
                          }
                        },
                        "expressionProperties": {
                          "templateOptions.disabled": "model.accountSigner !== \"yes\""
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "jobTitle",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Job Title",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Job Title is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 custom-tooltip-class",
                    "fieldGroup": [
                      {
                        "key": "mothersMaidenName",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Mother’s Maiden Name",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Mother’s Maiden Name is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "birthDate",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Birth Date",
                          "type": "date",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Birth Date is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "phone",
                        "type": "phone",
                        "templateOptions": {
                          "label": "Phone",
                          "required": true,
                          "mask": "(000) 000-0000",
                          "tooltip": "Phone Number is required."
                        },
                        "validation": {
                          "messages": {
                            "required": "Phone is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 custom-tooltip-class",
                    "fieldGroup": [
                      {
                        "key": "email",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Email",
                          "type": "email",
                          "required": true,
                          "pattern": "^\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,3}$",
                          "patternMessage": "Please enter a valid email address."
                        },
                        "validation": {
                          "messages": {
                            "required": "Email ID is required",
                            "pattern": "Please enter a valid email address."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "ssn",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Social Security Number",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Social Security Number is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "citizenship",
                        "type": "custom-multi-select",
                        "templateOptions": {
                          "label": "Citizenship",
                          "required": true,
                          "options": [
                            {
                              "value": "US Citizen",
                              "label": "US Citizen"
                            },
                            {
                              "value": "Permanent Resident",
                              "label": "Permanent Resident"
                            },
                            {
                              "value": "Non-Permanent Resident",
                              "label": "Non-Permanent Resident"
                            }
                          ]
                        },
                        "validation": {
                          "messages": {
                            "required": "Citizenship is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 custom-tooltip-class",
                    "fieldGroup": [
                      {
                        "key": "countryOfOrigin",
                        "type": "custom-multi-select",
                        "templateOptions": {
                          "label": "If not a US Citizen, indicate country of Origin",
                          "required": true,
                          "options": [
                            {
                              "value": "Country1",
                              "label": "Country1"
                            },
                            {
                              "value": "Country2",
                              "label": "Country2"
                            }
                          ]
                        },
                        "validation": {
                          "messages": {
                            "required": "Country of Origin is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "authorizedSigner",
                        "type": "custom-radio",
                        "templateOptions": {
                          "label": "Is this individual an authorized signer?",
                          "required": true,
                          "options": [
                            {
                              "value": "Yes",
                              "label": "Yes"
                            },
                            {
                              "value": "No",
                              "label": "No"
                            }
                          ]
                        },
                        "validation": {
                          "messages": {
                            "required": "Authorized signer is required."
                          }
                        },
                        "className": "custom-radio"
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "residentialAddress1",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Residential Street Address Line 1",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Residential Street Address Line 1 is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 custom-tooltip-class",
                    "fieldGroup": [
                      {
                        "key": "residentialAddress2",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Residential Street Address Line 2"
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "residentialCity",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Residential City",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Residential City is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "residentialState",
                        "type": "custom-multi-select",
                        "templateOptions": {
                          "label": "Residential State",
                          "required": true,
                          "options": []
                        },
                        "validation": {
                          "messages": {
                            "required": "Residential State is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 custom-tooltip-class",
                    "fieldGroup": [
                      {
                        "key": "residentialZip",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Residential Zip",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Residential Zip is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "residentialCounty",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Residential County",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Residential County is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "residentialCountry",
                        "type": "custom-multi-select",
                        "templateOptions": {
                          "label": "Residential Country",
                          "required": true,
                          "options": [],
                          "changeFunction": "onResidentialCountryChange"
                        },
                        "validation": {
                          "messages": {
                            "required": "Residential County is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 custom-tooltip-class",
                    "fieldGroup": [
                      {
                        "key": "sameAsMailing",
                        "type": "custom-radio",
                        "templateOptions": {
                          "label": "Is the residential address the same as the mailing address?",
                          "required": true,
                          "options": [
                            {
                              "value": "Yes",
                              "label": "Yes"
                            },
                            {
                              "value": "No",
                              "label": "No"
                            }
                          ]
                        },
                        "validation": {
                          "messages": {
                            "required": "At least one is required."
                          }
                        },
                        "className": "custom-radio"
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "photoIdNumber",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Photo ID Number",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Photo ID Number is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 mt-4",
                    "fieldGroup": [
                      {
                        "key": "photoIdIssueDate",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Photo ID Issue Date",
                          "type": "date",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Photo ID Issue Date is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 mt-4",
                    "fieldGroup": [
                      {
                        "key": "photoIdExpirationDate",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Photo ID Expiration Date",
                          "type": "date",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Photo ID Expiration Date is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 mt-4",
                    "fieldGroup": [
                      {
                        "key": "photoIdIssueDateState",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Photo ID State of Issue",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Photo ID State of Issue is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 mt-4",
                    "fieldGroup": [
                      {
                        "key": "photoIdIssueDateCountry",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Photo ID Country of Issue",
                          "required": true
                        },
                        "validation": {
                          "messages": {
                            "required": "Photo ID Country of Issue is required."
                          }
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 custom-tooltip-class mt-4",
                    "fieldGroup": [
                      {
                        "key": "mailingAddress",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Mailing Address (if different than residential)"
                        },
                        "expressionProperties": {
                          "templateOptions.disabled": "model.sameAsMailing === 'Yes'"
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "mailingAddress1",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Mailing Street Address Line 1"
                        },
                        "expressionProperties": {
                          "templateOptions.disabled": "model.sameAsMailing === 'Yes'"
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "mailingAddress2",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Mailing Street Address Line 2"
                        },
                        "expressionProperties": {
                          "templateOptions.disabled": "model.sameAsMailing === 'Yes'"
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 custom-tooltip-class",
                    "fieldGroup": [
                      {
                        "key": "mailingCity",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Mailing City"
                        },
                        "expressionProperties": {
                          "templateOptions.disabled": "model.sameAsMailing === 'Yes'"
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "mailingState",
                        "type": "custom-multi-select",
                        "templateOptions": {
                          "label": "Mailing State",
                          "options": []
                        },
                        "expressionProperties": {
                          "templateOptions.disabled": "model.sameAsMailing === 'Yes'"
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "mailingZip",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Mailing Zip"
                        },
                        "expressionProperties": {
                          "templateOptions.disabled": "model.sameAsMailing === 'Yes'"
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6 custom-tooltip-class",
                    "fieldGroup": [
                      {
                        "key": "mailingCounty",
                        "type": "custom-input",
                        "templateOptions": {
                          "label": "Mailing County"
                        },
                        "expressionProperties": {
                          "templateOptions.disabled": "model.sameAsMailing === 'Yes'"
                        }
                      }
                    ]
                  },
                  {
                    "type": "column",
                    "className": "col-md-6",
                    "fieldGroup": [
                      {
                        "key": "indMailingCountry",
                        "type": "custom-multi-select",
                        "templateOptions": {
                          "label": "Mailing Country",
                          "options": [],
                          "changeFunction": "onInMailingCountryChange"
                        },
                        "expressionProperties": {
                          "templateOptions.disabled": "model.sameAsMailing === 'Yes'"
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
    this.form.valueChanges.subscribe(value => {
      console.log('Form value changes:', value);
      this.model = value; // Sync model with form value
      });
  }

  exitApplication(){
    this.router.navigate(['/']).then(() => {
      window.location.reload(); 
    });
  }

  functionMap = {
    openRightSlideModalLic: this.openRightSlideModalLic.bind(this),
    openRightSlideModalInd: this.openRightSlideModalInd.bind(this),
    openRightSlideModalDocNotApplicable: this.openRightSlideModalDocNotApplicable.bind(this),
    openRightSlideModalDoc: this.openRightSlideModalDoc.bind(this),
    onregisteredCountryChange: this.onregisteredCountryChange.bind(this),
    onMailingCountryChange: this.onMailingCountryChange.bind(this),
    fictitiousBusinessName: (model: any, formState: any) => {
      return !!model.fictitiousBusinessName;
    }
  };

  bindFunctionsToTemplateOptions(fields: FormlyFieldConfig[]) {
    fields.forEach(field => {
      if (field.fieldGroup) {
        this.bindFunctionsToTemplateOptions(field.fieldGroup);
      } else if (field.templateOptions && field.templateOptions.onClick) {
        const functionName = field.templateOptions.onClick;
        if (this.functionMap[functionName]) {
          field.templateOptions.onClick = this.functionMap[functionName];
        }
      } else if (field.templateOptions && field.templateOptions.changeFunction) {
        const functionName = field.templateOptions.changeFunction;
        if (this.functionMap[functionName]) {
          field.templateOptions.change = this.functionMap[functionName];
        }
      }
      
    });
  }

  bindExpressionProperties(fields: FormlyFieldConfig[]) {
    return fields.map(field => {
      if (field.expressionProperties) {
        Object.keys(field.expressionProperties).forEach(key => {
          const functionName = field.expressionProperties[key] as string;
          if (this.functionMap[functionName]) {
            field.expressionProperties[key] = this.functionMap[functionName];
          }
        });
      }

      if (field.fieldGroup) {
        field.fieldGroup = this.bindExpressionProperties(field.fieldGroup);
      }

      return field;
    });
  }



  findFieldByKey(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig | null {
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }
      if (field.fieldGroup && field.fieldGroup.length) {
        const nestedField = this.findFieldByKey(field.fieldGroup, key);
        if (nestedField) {
          return nestedField;
        }
      }
    }
    return null;
  }
  
// get country list
getCountryList() {
  this.spinner.show();
  this._api.functionGET('country/allcountry', {}).subscribe((res) => {
    this.updateFieldOptions('registeredCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
      value: country.bt_country_id,
      label: country.name 
    })));
    this.updateFieldOptions('mailingCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
      value: country.bt_country_id,
      label: country.name 
    })));

    this.spinner.hide();
  },
    err => {
    });
}

  onregisteredCountryChange(field: FormlyFieldConfig, event: any) {
    console.log('--------------event', event);
    const selectedCountryId = event.value;
    this.loadStateOptions(selectedCountryId);
  }

  loadStateOptions(countryCode: string) {
    this.spinner.show();
    this._api.functionGET('country/cannabisStates?bt_country_id=' + countryCode).subscribe((data: { [x: string]: any[]; }) => {
      this.updateFieldOptions('registeredStateOrProvince', data['data'].map((country: { bt_region_id: any; name: any; }) => ({
        value: country.bt_region_id,
        label: country.name
      })));
      this.spinner.hide();
    });
  }
  
  updateFieldOptions(key: string, options: any[]) {
    const field = this.findFieldByKey(this.fields, key);
    if (field && field.templateOptions) {
      field.templateOptions.options = options;
    }
  }


  onMailingCountryChange(field: FormlyFieldConfig, event: any) {
    console.log('--------------event', event);
    const selectedCountryId = event.value;
    this.loadMailingStateOptions(selectedCountryId);
  }

  loadMailingStateOptions(countryCode: string) {
    this.spinner.show();
    this._api.functionGET('country/cannabisStates?bt_country_id=' + countryCode).subscribe((data) => {
      this.updateFieldOptions('mailingState', data['data'].map((country: { bt_region_id: any; name: any; }) => ({
        value: country.bt_region_id,
        label: country.name
      })));
      this.spinner.hide();
    });
  }


  openRightSlideModalLic() {
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
      width: '0px',
      position: { right: '0' },
      panelClass: 'slide-in-right',
      data: { tabType: 'lic_tab'},
      disableClose: true,
    });
  
    dialogRef.afterOpened().subscribe(() => {
      const dialogElement = document.querySelector('.slide-in-right .modal-container');
      if (dialogElement) {
        dialogElement.classList.add('open');
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
       this.model = {
        dynamicData: [...this.model.dynamicData, result]
      };
      // this.model.dynamicData = result;
      this.cdr.detectChanges();
      this.fields[0].fieldGroup[0].fieldGroup[0].fieldGroup[0].fieldGroup[0].templateOptions.data = this.model.dynamicData[0];
      console.log('=====================result', this.model.dynamicData[0]);
    });
  }

  openRightSlideModalInd() {
    console.log('Open Modal clicked');
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
        width: '0px',
        position: { right: '0' },
        panelClass: 'slide-in-right',
        data: { tabType: 'key_person_tab', indJsonForm: this.indJsonForm, modalWidth:'100'},
        disableClose: true,
      });
  
      dialogRef.afterOpened().subscribe(() => {
        const dialogElement = document.querySelector('.slide-in-right .modal-container');
        if (dialogElement) {
          dialogElement.classList.add('open');
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.appendDataToTable(result);
        }
      });
  }

  appendDataToTable(result: any) {
    const individualTableField = this.findFieldByKey(this.fields, 'individualTable');
    if (individualTableField) {
      const currentData = individualTableField.templateOptions?.['data'] || [];
      const newDataEntry: PersonData = {
        first_name: result.firstName || '',
        last_name: result.middleInitial || ''
      };

      this.model.dynamicData = [
        ...currentData,
        newDataEntry
      ];
      if (individualTableField.templateOptions) {
        individualTableField.templateOptions['data'] = this.model.dynamicData || [];
    }
      this.cdr.detectChanges();
    }
  }
  assignClickEvent() {
    // Assign the onClick event handler dynamically
    this.fields.forEach(field => {
      if (field.key === 'uploadDocBtn') {
        field.templateOptions.onClick = () => this.openRightSlideModalDoc(field.templateOptions.value);
      }
    });
  }
  openRightSlideModalDoc(value: any) {
    console.log('===============value', value);
    // const docTab = this.findFieldByKey(this.fields, value);
    // const title = docTab.templateOptions?.title
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
      width: '0px',
      position: { right: '0' },
      panelClass: 'slide-in-right',
      data: { tabType: 'document_tab', docType: 'title', isApplicable: false},
      disableClose: true,
    });
  
    dialogRef.afterOpened().subscribe(() => {
      const dialogElement = document.querySelector('.slide-in-right .modal-container');
      if (dialogElement) {
        dialogElement.classList.add('open');
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
       this.model = {
        dynamicData: [...this.model.dynamicData, result]
      };
      // this.model.dynamicData = result;
      this.cdr.detectChanges();
      this.fields[0].fieldGroup[0].fieldGroup[0].fieldGroup[0].fieldGroup[0].templateOptions.data = this.model.dynamicData[0];
      console.log('=====================result', this.model.dynamicData[0]);
    });
  }

  openRightSlideModalDocNotApplicable() {
    const docTab = this.findFieldByKey(this.fields, 'businessIncomeTax');
    const title = docTab.templateOptions?.title
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
      width: '0px',
      position: { right: '0' },
      panelClass: 'slide-in-right',
      data: { tabType: 'document_tab', docType: title, isApplicable: true},
      disableClose: true,
    });
  
    dialogRef.afterOpened().subscribe(() => {
      const dialogElement = document.querySelector('.slide-in-right .modal-container');
      if (dialogElement) {
        dialogElement.classList.add('open');
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
       this.model = {
        dynamicData: [...this.model.dynamicData, result]
      };
      // this.model.dynamicData = result;
      this.cdr.detectChanges();
      this.fields[0].fieldGroup[0].fieldGroup[0].fieldGroup[0].fieldGroup[0].templateOptions.data = this.model.dynamicData[0];
      console.log('=====================result', this.model.dynamicData[0]);
    });
  }

  submit(model: any) {
    console.log(this.model);
    alert(JSON.stringify(this.model));
  }

  fetch(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', `assets/100k.json`);

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    req.send();
  }
}

interface PersonData {
  first_name: string;
  last_name: string;
}

interface MyModel {
  dynamicData: PersonData[];
}

