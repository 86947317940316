import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-custom-tabs',
  template: `
    <mat-tab-group (selectedIndexChange)="onTabChange($event)" [(selectedIndex)]="to.selectedIndex">
      <mat-tab
        *ngFor="let tab of field.fieldGroup; let i = index; let last = last"
        [label]="tab.props.label">
        <formly-field [field]="tab"></formly-field>
      </mat-tab>
    </mat-tab-group>
  `,
})
export class FormlyCustomTabs extends FieldType {

  isValid(field: FormlyFieldConfig): boolean {
    // if (field.key) {
    //   return field.formControl.valid;
    // }
    return field.fieldGroup ? field.fieldGroup.every((f) => this.isValid(f)) : true;
  }

  onTabChange(index: number): void {
    console.log(`Selected tab index: ${index}`);
    if(this.to.selectedIndexChange){
      this.to.selectedIndexChange(index);
    }
  }

}