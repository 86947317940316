import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-formly-dynamic-modal',
  templateUrl: './formly-dynamic-modal.component.html',
  styleUrls: ['./formly-dynamic-modal.component.css']
})
export class FormlyDynamicModalComponent implements OnInit {
  tabType = '';
  searchinput: FormGroup;
  searchInput=''; 
  license_data: any;
  sortcolumn = '';
  sortorder = '';   
  selectedUsers: any[] = [];


  page = {
    pageSizeOptions: [10, 25, 50, 100]
    , limit: 25
    , pageSize: 25
    , index: 0
  };
  displayedColumns: string[] = [
    'Status'
    , 'Business Legal Name'
    , 'License Type'
    , 'Action'
  ];

  form = new FormGroup({});
  model: {
    dynamicData?: any[];
  }  = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  modalWidth: any;

  @Input() editModel: any;  // The selected row's data
  @Input() editFields: FormlyFieldConfig[];  // Formly JSON configuration for the fields
  @Output() editIndividual = new EventEmitter<any>();  // Emit updated data back to parent

  editIndForm = new FormGroup({});
  coutryCode: any;
  mailingCountry: any;
  countryKey: FormlyFieldConfig<import("@ngx-formly/core").FormlyFieldProps & { [additionalProperties: string]: any; }>;
  mailingCountryKey: FormlyFieldConfig<import("@ngx-formly/core").FormlyFieldProps & { [additionalProperties: string]: any; }>;
  country_id=''
  call_stateapi=false

  mailing_country_id=''
  mailing_call_stateapi=false

  docForm: FormGroup;
  selectedFile;
  AttachmentsName = '';
  apiImage: any;
  docTitle: any;
  formSubmitted = false;
  selectedFiles: File[] = [];
  apiImages: File[] = [];
  isApplicable: false;
  AttachFiles = [];
  attachNames = [];
  max_file_count=0;
  supportedFile={
   maxSize:'',
   fileSize:0,
   type:''
 };
 supportedDocType = '';
 supporetsFiles = '';
 supportMaxFileSize = '';
 @ViewChild('attachInput') attachInputRef: any;
  countryOfOriginCountry: any;
 
  constructor(
    @Optional() public dialogRef: MatDialogRef<any>, 
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, 
    private formBuilder: FormBuilder
    , private spinner: NgxSpinnerService
    , private _api: ApiService
    , private cookieService: CookieService
    , private _utils: UtilitiesService
  ) {
    this.searchinput = this.formBuilder.group({
      search_field: new FormControl(''),
  });
  this.docForm = this.formBuilder.group({
    appDoc: new FormControl('', Validators.required),
    comments: new FormControl('', Validators.required),
    uploadDocuments: [null],
});

  }

  ngOnInit(): void {
    this.tabType = this.data['tabType']
    if(this.tabType === 'key_person_tab') {

      this.fields = this.data['indJsonForm'];
      if(this.cookieService.get('token')){
        this.getCountryList();
        
        // Subscribe to form value changes
        this.form.valueChanges.subscribe(value => {
        console.log('Form value changes:', value['residentialCountry']);

        if(!this.country_id && value['residentialCountry']){
          this.country_id = value['residentialCountry'];
          this.call_stateapi=true;

        }else if(this.country_id && this.country_id!==value['residentialCountry']){
          this.country_id = value['residentialCountry'];
          this.call_stateapi=true;

        }else{
          this.call_stateapi=false;
        }

        if(!this.mailing_country_id && value['indMailingCountry']){
          this.mailing_country_id = value['indMailingCountry'];
          this.mailing_call_stateapi=true;

        }else if(this.mailing_country_id && this.mailing_country_id!==value['indMailingCountry']){
          this.mailing_country_id = value['indMailingCountry'];
          this.mailing_call_stateapi=true;

        }else{
          this.mailing_call_stateapi=false;
        }
        
        this.model = value; // Sync model with form value

        this.coutryCode = this.getValueByKey('residentialCountry');
        this.mailingCountry = this.getValueByKey('indMailingCountry');
        this.countryOfOriginCountry = this.getValueByKey('countryOfOrigin');

        if(this.call_stateapi){
          this.onResidentialCountryChange(this.coutryCode);
        }
        if(this.mailing_call_stateapi){
          this.onInMailingCountryChange(this.mailingCountry);
        }
        });
    }else{
      // this.exitApplication();
    }
    
      this.bindFunctionsToTemplateOptions(this.fields);
      this.modalWidth = this.data['modalWidth'];
    }

    if(this.tabType === 'edit_key_person_tab') {
      this.getCountryList();
      this.editFields = this.data['indJsonForm'];
      this.modalWidth = this.data['modalWidth'];
      this.editModel = this.data['editModel'];
    }
    if(this.tabType === 'document_tab') {
      this.getSupportedFiles();
      this.modalWidth = this.data['modalWidth'];
      this.docTitle = this.data['docType'];
      this.isApplicable = this.data['isApplicable'];
    // Conditionally required 'uploadDocuments' based on 'appDoc' value
    this.docForm.get('appDoc')?.valueChanges.subscribe((value) => {
      if (value === 'upload_from_device') {
        this.docForm.get('uploadDocuments')?.setValidators(Validators.required);
        this.docForm.get('comments')?.setValidators(Validators.required);
      } else {
        this.docForm.reset();
        this.docForm.get('uploadDocuments')?.clearValidators();
        this.docForm.get('comments')?.clearValidators();
      }
      this.docForm.get('uploadDocuments')?.updateValueAndValidity();
      this.docForm.get('comments')?.updateValueAndValidity();
    });
    
    }
    if(this.tabType === 'lic_tab') {
      this.modalWidth = this.data['modalWidth'];
    }
}

functionMap = {
  onResidentialCountryChange: this.onResidentialCountryChange.bind(this),
  onInMailingCountryChange: this.onInMailingCountryChange.bind(this),
};

bindFunctionsToTemplateOptions(fields: FormlyFieldConfig[]) {
  fields.forEach(field => {
    if (field.fieldGroup) {
      this.bindFunctionsToTemplateOptions(field.fieldGroup);
    } else if (field.templateOptions && field.templateOptions.onClick) {
      const functionName = field.templateOptions.onClick;
      if (this.functionMap[functionName]) {
        field.templateOptions.onClick = this.functionMap[functionName];
      }
    }
  });
}

findFieldByKey(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig | null {
  for (const field of fields) {
    if (field.key === key) {
      return field;
    }
    if (field.fieldGroup && field.fieldGroup.length) {
      const nestedField = this.findFieldByKey(field.fieldGroup, key);
      if (nestedField) {
        return nestedField;
      }
    }
  }
  return null;
}

getValueByKey(key: string) {
  return this.form.get(key)?.value;
}

// get country list
getCountryList() {
  this.spinner.show();
  const apiData = {
    instance_id: this.cookieService.get('instance_id'),
    email: this.cookieService.get('email')
  };
  this._api.functionPOST('web/countriesList', apiData).subscribe((res) => {
    this.updateFieldOptions('residentialCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
      value: country.bt_country_id,
      label: country.name 
    })));
    this.updateFieldOptions('indMailingCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
      value: country.bt_country_id,
      label: country.name 
    })));
    this.updateFieldOptions('countryOfOrigin', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
      value: country.bt_country_id,
      label: country.name 
    })));

    this.spinner.hide();
  },
    err => {
    });
}

onResidentialCountryChange(event: any) {
    this.loadStateOptions(event); 
  }

  loadStateOptions(countryCode: string) {
    this.spinner.show();
    const apiData = {
      instance_id: this.cookieService.get('instance_id'),
      email: this.cookieService.get('email'),
      country_id: countryCode
    };
    this._api.functionPOST('web/statesList', apiData).subscribe((data: { [x: string]: any[]; }) => {
      this.updateFieldOptions('residentialState', data['data'].map((country: { bt_region_id: any; name: any; }) => ({
        value: country.bt_region_id,
        label: country.name
      })));
      this.spinner.hide();
    });
  }
  
  updateFieldOptions(key: string, options: any[]) {
    const field = this.findFieldByKey(this.fields, key);
    if (field && field.templateOptions) {
      field.templateOptions.options = options;
    }
  }

  onInMailingCountryChange(event: any) {
    this.loadMailingStateOptions(event);
  }

  loadMailingStateOptions(countryCode: string) {
    this.spinner.show();
    const apiData = {
      instance_id: this.cookieService.get('instance_id'),
      email: this.cookieService.get('email'),
      country_id: countryCode
    };

    this._api.functionPOST('web/statesList', apiData).subscribe((data) => {
      this.updateFieldOptions('mailingState', data['data'].map((country: { bt_region_id: any; name: any; }) => ({
        value: country.bt_region_id,
        label: country.name
      })));
      this.spinner.hide();
    });
  }

  close() {
    this.call_stateapi=false;
    this.mailing_call_stateapi=false;
    this.form.reset();     // Reset the form controls
    this.model = {};       // Reset the model
    this.dialogRef.close();
  }

  submit() {
    // if (this.form.valid) {
      this.dialogRef.close(this.model);
    // }
  }

  // Edit Ind Form
  onEditIndSubmit() {
    // if (this.form.valid) {
      // Emit the updated model back to the parent component
      // this.editIndividual.emit(this.editModel);
      // $('#editModal').modal('hide');  // Close modal after save
      this.dialogRef.close(this.editModel);
    // }
  }

// Serach License
  filterValidation(tag){
    let u_search_f = this.searchinput.get('search_field').value;

    if (!u_search_f) {
      this._api.openSnackBar('Search field is empty.', 'Error');
    }

    this.getLicDetails({ page: 1 });
  }

  getLicDetails(data?) {
  const apiData = { 
    'search_field': this.searchinput.get('search_field').value, 
    instance_id: this.cookieService.get('instance_id'),
    email: this.cookieService.get('email')
    }
    if (this.sortcolumn !== '') {
      apiData['sortcolumn'] = this.sortcolumn;
    }
    if (this.sortorder !== '') {
      apiData['sortorder'] = this.sortorder;
    }
  console.log('---------------data', apiData);

    this.spinner.show();
    this._api.functionPOST('web/licenseSearch', apiData).subscribe((response) => {
      console.log('------------------res', response);
      this.license_data = response.data.data
      this.spinner.hide();
    });
  }

  sortData($event) {
    this.sortcolumn = $event.active;
    this.sortorder = $event.direction.toUpperCase();
    this.page.index=0
    this.getLicDetails({ page: 1 });
  }

  PageChange($event) {
    this.page.pageSize = $event.pageSize;
    this.page.index = $event.pageIndex;
    this.getLicDetails({ page: $event.pageIndex + 1 });
  }

   onCheckboxChange(user: any, event: any) {
     if (event.target.checked) {
       this.selectedUsers.push(user);
       console.log('---------------selected', this.selectedUsers);
     } else {
       this.selectedUsers = this.selectedUsers.filter(u => u.id !== user.id);
     }
   }

   submitSelectedRow() {
    this.dialogRef.close(this.selectedUsers);
    console.log('--------------------------submitted', this.selectedUsers);

   }

// Doc Tab Action
  //  uploadDocform = new FormGroup({});
  //  uploadDocmodel = {};
  //  uploadDocfields: FormlyFieldConfig[] = [
  //    {
  //          "fieldGroup": [
  //            {
  //              "type": 'section',
  //              "templateOptions": { 
  //                "label": 'Upload Document',
  //                "className": 'custom-section-header',
  //              },
  //              "fieldGroupClassName": 'custom-section',
  //              "fieldGroup": [
  //                {
  //                  "type": 'row',
  //                  "fieldGroup": [
  //                    {
  //                      "type": 'column',
  //                      "className": 'col-md-12',
  //                      "fieldGroup": [
  //                        {
  //                          "key": "docUpload",
  //                          "type": "radio",
  //                          "templateOptions": {
  //                            "required": true,
  //                            "options": [
  //                              { "value": "upload_from_device", "label": "Upload from Your Device" },
  //                              { "value": "not_applicable", "label": "Not Applicable" }
  //                            ]
  //                          }
  //                        },
 
  //                        {
  //                          "key": 'incorporationDocuments',
  //                          "type": 'file',
  //                          "templateOptions": {
  //                            // "label": 'Articles of Incorporation & Other Formation Documents',
  //                            "placeholder": 'Upload your documents',
  //                            "required": true,
  //                            // "multiple": true,
  //                            "accept": '.pdf,.doc,.docx,.jpg,.png',
  //                            "description": 'Please upload the required documents (PDF, DOC, DOCX, JPG, PNG).'
  //                          },
  //                          "hideExpression": "!model.docUpload || model.docUpload === 'not_applicable'",
  //                          "validation": {
  //                            "messages": {
  //                              "required": "Articles of Incorporation & Other Formation Documents is required."
  //                            }
  //                          }
  //                        }
 
  //                      ],
  //                    },
 
  //                    {
  //                      "type": 'column',
  //                      "className": 'col-md-12 mt-4',
  //                      "fieldGroup": [
  //                        {
  //                          "key": "comments",
  //                          "type": "textarea",
  //                          "templateOptions": {
  //                            "label": "Comments",
  //                            "required": true,
  //                            "rows": 5,
                             
  //                          },
  //                          "className": 'custom-textarea-wrapper',
  //                        },
  //                      ],
  //                    },
 
  //                  ],
  //                },
  //              ],
  //            },
  //          ],
  //    }
  //  ];
 
  //  submitDocument() {
  //    if (this.uploadDocform.valid) {
  //      console.log(this.uploadDocmodel);
  //      this.dialogRef.close(this.uploadDocmodel);
  //    }
  //  }

  getSupportedFiles() {

    const apiData = {
      instance_id: this.cookieService.get('instance_id'),
      email: this.cookieService.get('email')
    };
    this._api.functionPOST('web/getSupportedFileDetails', apiData).subscribe((res) => {
      if (res['data'] ) {
        const files   =  res['data']['supported_file_types'];
        this.supportedDocType = files.split(',');
      }
      var size = (res['data']['max_file_size_bytes']/1000000)+' MB';
      this.supportedFile={
        maxSize:size,
        fileSize:res['data']['max_file_size_bytes'],
        type:res['data']['supported_file_types']
      };
      this.max_file_count = parseInt(res['data']['max_file_count'])
     this.supporetsFiles = '* Supported document types are ' + res['data']['supported_file_types'];
     this.supportMaxFileSize = '* Supported maximum file count is '+ this.max_file_count +
     ' and file size is ' + this.supportedFile['maxSize'];
    },
      err => {
      });
  }

  
  onFileSelected(event: any) {
    if(this.AttachFiles.length>=this.max_file_count){
      this._api.openSnackBar('Supported maximum file count should be less than or equal to '+this.max_file_count, 'Error');
      return;
    }
    for (let i = 0; i <= event.target.files.length - 1; i++) {
      const file = event.target.files[i];
      console.log('-------------file', file)
      // if (file) {
      //   const extn = file.name.split('.').pop();
      //   if (this.supportedDocType.includes(extn.toLowerCase())) {
      //     if (file.size > this.supportedFile['fileSize']) {
      //         this._api.openSnackBar('Size should be less than '+this.supportedFile['maxSize'], 'Error');
      //         this.attachInputRef.nativeElement.value = '';
      //       return false;
      //       }
      //     } else {
      //       event.target.value='';
      //       this._api.openSnackBar('File not supported', 'Error');
      //       this.attachInputRef.nativeElement.value = '';
      //       return false;
      //     }
      // }
  const selectedFile = event.target.files[i];
  if(this.AttachFiles.length>=this.max_file_count){
    this._api.openSnackBar('Supported maximum file count should be less than or equal to '+this.max_file_count, 'Error');
    return;
  }
  this.AttachFiles.push(selectedFile);
  this.attachNames.push(selectedFile.name);
  }
  // this.attachInputRef.nativeElement.value = '';
  }

  
  uploadNotAppDoc() {
    this.formSubmitted = true;
    const docComments = this.docForm.get('comments').value;
    
      const apiData = new FormData();
      apiData.append('instance_id', this.cookieService.get('instance_id'));
      apiData.append('email', this.cookieService.get('email'));
      apiData.append('doc_type', this.docTitle);
      apiData.append('doc_comment', docComments);
      apiData.append('isNotApplicable', 'Y');
      console.log('---------------apiData', apiData);
      this._api.functionPOST('web/uploadDocument' , apiData).subscribe((response) => {
        if(response['success']){
          this._api.openSnackBar(response['data'] , 'Success');
          this.selectedFile = '';
          this.apiImage = '';
          this.docForm.reset();
          this.dialogRef.close(true);
        }
      },
      err => {
        this._api.openSnackBar(err.error, 'Error');
      });
      this.spinner.hide();
    }
  
  uploadAppDoc() {
    this.formSubmitted = true;
    // if (this.docForm.invalid) {
    //   return;
    // }
    this.spinner.show();
      // if(!this.selectedFile){
      //   this._api.openSnackBar('Select a file', 'Error');
      //   return false;
      // }
    const docComments = this.docForm.get('comments').value;
     
      const apiData = new FormData();
      // if (this.apiImages.length > 0) { 
      //   this.apiImages.forEach((file, index) => {
      //     apiData.append(`files${index}`, file, file.name);
      //   });
      // }
      // if (this.apiImage) { 
      //     apiData.append('files', this.apiImage);
      // }

      if (this.AttachFiles.length > 0) {
        this.AttachFiles.map((item) => {
          apiData.append('files', item);
        });
        }

      apiData.append('instance_id', this.cookieService.get('instance_id'));
      apiData.append('email', this.cookieService.get('email'));
      apiData.append('doc_type', this.docTitle);
      apiData.append('doc_comment', docComments);
      apiData.append('is_pii', 'N');
      apiData.append('isNotApplicable', 'N');
      console.log('---------------apiData', apiData);
      this._api.functionPOST('web/uploadDocument' , apiData).subscribe((response) => {
        if(response['success']){
          this._api.openSnackBar(response['data'] , 'Success');
          this.selectedFile = '';
          this.apiImage = '';
          this.docForm.reset();
          this.dialogRef.close(true);
        }
      },
      err => {
        this._api.openSnackBar(err.error, 'Error');
      });
      this.spinner.hide();
    }
   
}
