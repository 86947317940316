import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import SignaturePad from 'signature_pad';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-custom-signature',
  templateUrl: './custom-signature.component.html',
  styleUrls: ['./custom-signature.component.css']
})
export class CustomSignatureComponent extends FieldType implements AfterViewInit {
  @ViewChild('signaturePadCanvas', { static: true }) signaturePadCanvas!: ElementRef<HTMLCanvasElement>;
  private signaturePad!: SignaturePad;
  clickedPhoto: File;
  private padding = 30;
  debouncedResize: any;
  site_visit_images: any;
  site_visit_id: string;

  constructor(
    public _api: ApiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private cookieService: CookieService
  ) {
    super();
  }
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.site_visit_id = params.get('id');
    });
  }

  ngAfterViewInit(): void {
    if (this.signaturePadCanvas?.nativeElement) {
      this.initializeSignaturePad();
      window.addEventListener('resize', this.drawLine.bind(this));
      const debouncedResize = this.debounce(this.handleResize, 200);
      window.addEventListener('resize', debouncedResize);
      this.getSignature();
    }
  }

  @HostListener('window:resize')
  adjustCanvas() {
    const canvas = this.signaturePadCanvas.nativeElement;
    const parentWidth = canvas.parentElement?.clientWidth || window.innerWidth;
    canvas.width = parentWidth;
    canvas.height = 200;
  }

  drawLine() {
    const canvas = this.signaturePadCanvas.nativeElement;
    const context = canvas.getContext('2d');
    if (context) {
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const midY = canvasHeight / 1.2;
      context.clearRect(0, 0, canvasWidth, canvasHeight);
      context.strokeStyle = '#8f8f7c';
      context.lineWidth = 1;
      context.beginPath();
      context.moveTo(this.padding, midY);              
      context.lineTo(canvasWidth - this.padding, midY); 
      context.stroke();
    }
  }

  initializeSignaturePad(sURL?: any): void {
    const canvas = this.signaturePadCanvas.nativeElement;
    const ratio = Math.max(window.devicePixelRatio || 1, 1); // Device Pixel Ratio
    canvas.width = canvas.offsetWidth * ratio; // Set the canvas width to match the display size
    canvas.height = canvas.offsetHeight * ratio; // Set the canvas height to match the display size
    const ctx = canvas.getContext('2d');
    ctx.scale(ratio, ratio); // Scale the canvas context to match DPI
    this.signaturePad = new SignaturePad(canvas);
    this.signaturePad.penColor = "rgba(18,18,221,255)";
    if (this.formControl.value){
      const signatureData = this.signaturePad.toDataURL();
    }
    else if(this.site_visit_images) {
      this.signaturePad.fromDataURL(this.site_visit_images);
    }
    this.adjustCanvas();
    this.drawLine();

  }

  restoreSignature() {
    if (this.model.sign) {
      const canvas = this.signaturePadCanvas.nativeElement;
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => ctx?.drawImage(img, 0, 0);
      img.src = this.model.sign; // Restore from form model
    }
  }

  debounce(fn: Function, delay: number) {
    let timer: any;
    return function (...args: any[]) {
      clearTimeout(timer);
      timer = setTimeout(() => fn.apply(this, args), delay);
    };
  }

  handleResize() {
    // Save signature data before resizing
    const data = this.signaturePad.toData();

    // Adjust canvas dimensions
    const canvas = this.signaturePadCanvas.nativeElement;
    canvas.width = canvas.offsetWidth;
    canvas.height = 200; // Reapply height

    // Reinitialize signature pad
    this.signaturePad.clear();
    this.signaturePad.fromData(data); // Restore saved signature
  }

  ngOnDestroy(): void {
    if (this.signaturePad) {
      this.signaturePad.off();
    }
    window.removeEventListener('resize', this.drawLine.bind(this));
    window.removeEventListener('resize', this.debouncedResize);
  }

  clearSignature(): void {
    this.signaturePad.clear();
    this.formControl.setValue(null);
    this.adjustCanvas();
    this.drawLine();
  }

  convertDataURLToBlob(dataURL: string): Blob {
    const parts = dataURL.split(',');
    const mimeType = parts[0].match(/:(.*?);/)[1];
    const byteString = atob(parts[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([uint8Array], { type: mimeType });
  }
  
  triggerUpload() {
    if (this.to.onClick) {
      if (this.signaturePad && !this.signaturePad.isEmpty()) {
        const timestamp = new Date().toISOString();
        const signatureData = this.signaturePad.toDataURL(); // Base64 Image Data
        const blob = this.convertDataURLToBlob(signatureData); // Convert to Blob
        const fileName = 'signature.png';
        const file = new File([blob], fileName, { type: blob.type });
        this.spinner.show();
        const formData = new FormData();
        formData.append('site_visit_id', this.to.description);
        formData.append('file',  file);
        formData.append('img_capture_timestamp', timestamp);
        formData.append('latitude',  this.cookieService.get('latitude'));
        formData.append('longitude', this.cookieService.get('longitude'));
        this.spinner.show();
        this._api.functionPOST('web/siteVisit/uploadSignature', formData).subscribe((response) => {
          this._api.openSnackBar(response['data'], 'Success');
          this.clickedPhoto = null;
          this.spinner.hide();
          this.to.onClick(this.formControl.value);
          this.getSignature();
        });
    } else {
      this._api.openSnackBar("Signature is empty.", 'Error');
        return;
    }
    }
  }

  getSignature(){
    const apiData = {
      site_visit_id: this.site_visit_id,
      latitude: this.cookieService.get('latitude'),
      longitude:  this.cookieService.get('longitude')
    };
    this.spinner.show();
    this._api.functionPOST('web/siteviste/getSignature', apiData).subscribe((response) => {
      this.site_visit_images=response['data']['image'];
      if (this.signaturePadCanvas?.nativeElement) {
        this.initializeSignaturePad();
        window.addEventListener('resize', this.drawLine.bind(this));
        const debouncedResize = this.debounce(this.handleResize, 200);
        window.addEventListener('resize', debouncedResize);
      }
      this.spinner.hide();
      });
  }
  
}
