import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-custom-column',
  template: `
    <div [ngClass]="to.className">
      <formly-field *ngFor="let field of field.fieldGroup" [field]="field"></formly-field>
    </div>
  `,
})
export class FormlyCustomColumn extends FieldType {}
