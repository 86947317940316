import { environment } from './../environments/environment';
const env = environment;

//Dynamic variables : this variables will changes it's value with respect its environment
// console.log(`
//     You are in ${env.name}-mode
//     frontend : ${env.FRONTEND},
//     backend-api : ${env.BACKEND},
// `);

export const urls = env;
export const BACKEND = env.BACKEND;
export const FRONTEND = env.FRONTEND;
//IDme Client-id
export const client_id = env.client_id;
export const idme_url = env.idme_url;
export const idme_scope = env.idme_scope;
export const idme_redirect_uri = env.idme_redirect_uri;

//Dynamic variables
export const reCaptchaSiteKey = env.reCaptchaSiteKey;
export const reCaptcha2SiteKey = env.reCaptcha2SiteKey;
export const tokenType = 'Bearer ';
export const redirect_uri = BACKEND + 'user/idmeAuth';
export const response_type = 'code';
export const scope = 'authentication';
export const state = '488e864b';
export const environment_state  = env.name;