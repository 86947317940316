<div class="modal-container" [style.width.%]="modalWidth">
    <div *ngIf="tabType === 'key_person_tab'">
        <div class="modal-header">
          <h2>Add Individual</h2>
          <button mat-icon-button (click)="close()">
              <mat-icon>close</mat-icon>
          </button>
        </div>

        <form [formGroup]="form" (ngSubmit)="submit()" style="margin-bottom: 60px;">
          <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
        
          <button class="blue_button float-end ms-3 me-3" mat-raised-button type="submit" >Submit</button>
          <button class="float-end" mat-raised-button color="secondary" (click)="close()">Close</button>
        </form>
    </div>
    
    <div *ngIf="tabType === 'edit_key_person_tab'">
      <div class="modal-header">
        <h2>Edit Individual</h2>
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
      </div>
    
      <form [formGroup]="editIndForm" (ngSubmit)="onEditIndSubmit()"  style="margin-bottom: 60px;">
        <formly-form [form]="editIndForm" [fields]="editFields" [model]="editModel"></formly-form>
      
        <button class="blue_button float-end ms-3 me-3" mat-raised-button  type="submit" (click)="onEditIndSubmit()">Update</button>
        <button class="float-end" mat-raised-button color="secondary" (click)="close()">Close</button>
      </form>
    
    </div>

    <div *ngIf="tabType === 'document_tab'">
        <div class="modal-header">
          <h2>Add Document</h2>
          <button mat-icon-button (click)="close()">
              <mat-icon>close</mat-icon>
          </button>
        </div>
        <form [formGroup]="docForm" class="mt-4">
            <div class="row" *ngIf="!isApplicable">
              <div class="col-md-12">
                  <div class="form-group">
                    <div class="radio-group mt-4" style="display: flex; gap: 15px;">
                      <label class="radio-label text-dark">
                        <input type="radio" formControlName="appDoc" value="upload_from_device" required>
                        Upload from Your Device
                      </label>
                      <!-- <label class="radio-label text-dark">
                        <input type="radio" formControlName="appDoc" value="not_applicable" required>
                        Not Applicable
                      </label> -->
                    </div>
                    <div *ngIf="docForm.get('appDoc')?.invalid && formSubmitted" class="text-danger">
                      Please select an option.
                    </div>
                  </div>

                  <div class="form-group mt-4" *ngIf="docForm.get('appDoc')?.value === 'upload_from_device'">
                    <label for="fileUpload">{{docTitle}}</label>
                    <input
                      type="file"
                      id="fileUpload"
                      formControlName="uploadDocuments"
                      [attr.placeholder]="docForm.get('uploadDocuments')?.placeholder"
                      accept=".pdf,.doc,.docx,.jpg,.png"
                      required
                      class="form-control"
                      (change)="onFileSelected($event)"
                      multiple
                    />
                    <small class="form-text text-muted">
                      Please upload the required documents (PDF, JPG, PNG).
                    </small>
                    <div *ngIf="docForm.get('uploadDocuments')?.hasError('required') && formSubmitted">
                      <span class="text-danger">{{docTitle}} is required.</span>
                    </div>
                    <div *ngIf="selectedFiles.length > 0" class="mt-2">
                      <p>Selected files:</p>
                      <ul>
                        <li *ngFor="let file of selectedFiles">{{ file.name }}</li>
                      </ul>
                    </div>
                  </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-md-12 mt-4">
                <div class="custom-textarea-wrapper">
                  <label for="comments">Comments*</label>
                  <textarea
                    id="comments"
                    formControlName="comments"
                    rows="5"
                    required
                    class="form-control"
                  ></textarea>
                  <div *ngIf="docForm.get('comments')?.hasError('required') && formSubmitted">
                    <span class="text-danger">Comments are required.</span>
                  </div>
                </div>
              </div>
            </div>

          <!-- <input type="file" formControlName="appDoc" #fileInput (change)="onFileSelected($event)" multiple> -->
          <!-- <formly-form [model]="uploadDocmodel" [fields]="uploadDocfields" [form]="uploadDocform"></formly-form> -->
            <button *ngIf="!isApplicable" class="blue_button float-end ms-3" mat-raised-button type="submit" (click)="uploadAppDoc()">Submit222</button>
            <button *ngIf="isApplicable" class="blue_button float-end ms-3" mat-raised-button type="submit" (click)="uploadNotAppDoc()">Submit111</button>
            <button class="float-end ms-3" mat-raised-button color="secondary" (click)="close()" >Close</button>
        </form>
    
    </div>

    <div *ngIf="tabType === 'lic_tab'">

      <div class="modal-header">
        <h2>Add License</h2>
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
      </div>
      <form [formGroup]='searchinput'>
        <div class="comply row mt-4">
            <div class="col-sm-9 col-xs-12 col-lg-9 col-md-9">
                <mat-form-field class=" w-100">
                    <input matInput placeholder="License" formControlName='search_field'>
                    <mat-error>Maximum 100 Characters Allowed </mat-error>
                </mat-form-field>
                <div class="close-btn right-3" *ngIf="searchinput.value.search_field"
                    (click)="searchinput.patchValue({search_field :''});filterValidation(0)">
                </div>
            </div>
            <div class="col-sm-2 col-xs-12 col-lg-2 col-md-2">
                <button class="blue_button" mat-flat-button (click)="filterValidation(1)">Search</button>
            </div>
  
        </div>
    </form>
  
    <div class="checkBoxlign">
      <div class="table-responsive striped users_mod limit_height">
          <table class="col-12 multi_header_table fixed_layout  scrollling_table"
              (matSortChange)="sortData($event)" mat-table matSort [dataSource]="license_data">
              <ng-container matColumnDef="Status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bp_license_status_name" class="w120-container date-width">
                      Status
                  </th>
                  <td mat-cell *matCellDef="let element">{{element?.bp_license_status_name}}</td>
              </ng-container>
              
              <ng-container matColumnDef="Business Legal Name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="business_legal_name"> Business Legal Name
                  </th>
                  <td mat-cell *matCellDef="let element"> {{element?.business_legal_name}}</td>
              </ng-container>
  
              <ng-container matColumnDef="License Type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bp_license_type_name"> License Type
                </th>
                <td mat-cell *matCellDef="let element"> {{element?.bp_license_type_name}}</td>
            </ng-container>
  
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef> Action</th>
              <td mat-cell *matCellDef="let element"> 
                <input type="checkbox" (change)="onCheckboxChange(element, $event)">
                <!-- {{element?.bp_license_type_name}} -->
              </td>
          </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
      </div>
      <div class="col-12 text-center" *ngIf="license_data?.length == 0">
          <span class="NoDataFound">
              No Data Found
          </span>
      </div>
      <div class="col-12 bgWhite">
          <div class="row align-items-center ">
              <div class="col-12">
                  <mat-paginator *ngIf="license_data?.length != 0"
                      [pageSizeOptions]="page.pageSizeOptions" [length]="page.limit"
                      [pageSize]="page.pageSize" (page)="PageChange($event)" [pageIndex]="page.index"
                      showFirstLastButtons></mat-paginator>
              </div>
          </div>
      </div>
      <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12 mt-4 mb-4">
        <button class="blue_button mb-4 float-right" mat-flat-button (click)="submitSelectedRow()">Submit</button>
      </div>
     
  </div>
  
  
  </div>
  
    
    </div>