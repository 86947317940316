import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldType } from '@ngx-formly/core';
import { FormlyDynamicModalComponent } from '../popups/formly-dynamic-modal/formly-dynamic-modal.component';

@Component({
  selector: 'app-formly-custom-individual-table',
  template: `
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th *ngFor="let col of to['columns']">{{ col.label }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of to['data']; let i = index">
          <td *ngFor="let col of to['columns']">{{ row[col.key] }}</td>
          <td>
          <button class="blue_button" mat-raised-button (click)="editRow(row, i)">Edit</button>
          <button class="ms-3" mat-raised-button color="danger" (click)="deleteRow(i)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  `,
  styles: [
    `
      .table-responsive {
        overflow-x: auto;
      }
    `,
  ],
})
export class FormlyCustomIndividualTable extends FieldType implements OnInit {
  constructor(
    private dialog: MatDialog,
  ) {
    super();
  }
  editIndModel = {};
  isEditing = false;
  editingIndex: number | null = null;

  ngOnInit(): void {
    // console.log('----------------------indi form', this.to.indForm);
  }
  deleteRow(index: number): void {
    const currentData = this.model.dynamicIndData;
    currentData.splice(index, 1);
    this.model.dynamicIndData = currentData
  }

  editRow(row: any, index: number): void {
    console.log('000000000000000000', row);
    this.isEditing = true;
    this.editingIndex = index;
    console.log('--------------indexxxxxx', index, this.model.dynamicIndData);
    this.editIndModel = { ...this.model.dynamicIndData[index] };
    console.log('--------------editIndModelllll', this.editIndModel);
    this.openRightSlideModalEditInd(this.editIndModel)
  }

  openRightSlideModalEditInd(row: any) {
    console.log('--------------rowwwwww', row);
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
        width: '0px',
        position: { right: '0' },
        panelClass: 'slide-in-right',
        data: {
          tabType: 'edit_key_person_tab',
          editModel: { ...row },
          indJsonForm: this.to.indForm,
          modalWidth:'45'},
        disableClose: true,
      });

      dialogRef.afterOpened().subscribe(() => {
        const dialogElement = document.querySelector('.slide-in-right .modal-container');
        if (dialogElement) {
          dialogElement.classList.add('open');
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          console.log('-----------------result', result);
          this.to.onClick(result, this.editingIndex);
        }
      });
  }
}
