

  <label class="mb-2" [for]="id" *ngIf="to.label">{{ to.label }}</label>
  <label class="mb-2" *ngIf="to.description" hidden>{{ to.description }}</label>
  <label class="mb-2 mt-2 fw-bold fs-6">Signature</label>
  <div class="signature-container">
    <canvas #signaturePadCanvas></canvas>
    <div class="signature-actions">
      <button type="button" class="btn btn-danger" (click)="clearSignature()">Clear</button>
      <button type="button" class="btn btn-primary" (click)="triggerUpload()">
        {{ to.uploadButtonText || 'Upload' }}
      </button>
    </div>
  </div>
