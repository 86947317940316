import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-application-access-verification',
  templateUrl: './application-access-verification.component.html',
  styleUrls: ['./application-access-verification.component.css']
})
export class ApplicationAccessVerificationComponent implements OnInit {
  userForm: FormGroup;
  isV2CaptchaRequired: boolean = false;
  siteKey: string = environment.reCaptcha2SiteKey
  
  constructor(
    private route: ActivatedRoute
   , private router: Router
   , private _api: ApiService
   , private spinner: NgxSpinnerService
   , private formBuilder: FormBuilder
   , private cookieService: CookieService
   ) {
     this.userForm = formBuilder.group({
       motherSirName: new FormControl('', [Validators.required]),
       lastName: new FormControl('', [Validators.required]),
       email: new FormControl('', [Validators.required, Validators.email])
     })
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.cookieService.set('instance_id',  params.get('id'));
    });
  }

  formValidation(tag: number){
    let motherSirName = this.userForm.get('motherSirName')?.value;
    let lastName = this.userForm.get('lastName')?.value;
    let email = this.userForm.get('email')?.value;
    if(!lastName){
      if(tag==1){
        this._api.openSnackBar('Enter Last Name', 'Error');
      }
      return;
    }
    if(!email){
      if(tag==1){
        this._api.openSnackBar('Enter Email', 'Error');
      }
      return;
    }
    if(!motherSirName){
      if(tag==1){
        this._api.openSnackBar("Enter Mother's Maiden Name", 'Error');
      }
      return;
    }
  this.submitAccessVerification();
  }

  submitAccessVerification(v2token?){
    let isV2Required;
    if(v2token){
      isV2Required = true;
    }
    let motherSirName = this.userForm.get('motherSirName')?.value;
    let lastName = this.userForm.get('lastName')?.value;
    let email = this.userForm.get('email')?.value;

    this.spinner.show();
    const apiData = {
      instance_id: this.cookieService.get('instance_id'),
      lastname: lastName,
      email: email,
      mother_maiden_name: motherSirName,
      latitude: this.cookieService.get('latitude'),
      longitude:  this.cookieService.get('longitude'),
      isV2CaptchaRequired: isV2Required
    };
    this._api.functionPOST('web/authendicateUser', apiData, v2token).subscribe((response) => {
      if(response['success'] && !response['data']['isV2CaptchaRequired']){
        this.isV2CaptchaRequired = false;
      this.cookieService.set('instance_id', response['data']['df_instance_id']);
      this.cookieService.set('token', response['data']['token']);
      this.cookieService.set('email', response['data']['email']);

      if(response['data']['token'] && !response['data']['show_app_status']){
        this.router.navigate(['selected-application/'+response['data']['df_instance_id']]);

      } else if(response['data']['show_app_status'] && response['data']['token']){
        this.router.navigate(['application-status/'+response['data']['df_instance_id']]);
      }

    }  else if(response['data']['isV2CaptchaRequired']) {
      this.isV2CaptchaRequired = response['data']['isV2CaptchaRequired'];
    }
    this.spinner.hide();
    });
  }

  goToHome(){
    this.router.navigate(['/']);
  }

  handleSuccess(captchaResponse: string): void {
    console.log('CAPTCHA Response:', captchaResponse);
    this.submitAccessVerification(captchaResponse);
    // Use the response token for server-side validation
  }
  
  handleError(error: any): void {
    console.error('CAPTCHA Error:', error);
  }
  
  handleExpired(): void {
    console.warn('CAPTCHA Expired');
    // Optionally reset the CAPTCHA if needed
  }
}
