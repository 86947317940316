import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

// *ngIf="to.isDocAttached === 0"
@Component({
  selector: 'formly-label-value',
  template: `
    <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center mb-3">
      <span  *ngIf="to.value" class="label fw-bold me-2" [ngStyle]="to.labelStyle">{{ to.label }}:</span>
      <span *ngIf="!to.isDate"
      class="value badge fw-bold" 
      [ngClass]="{
        'bg-success text-white': to.value === 'Uploaded',
        'bg-info': to.value === 'Not Applicable'
      }">
      {{ to.value || '' }}
    </span>
      <span *ngIf="to.isDate" class="value fw-bold" [ngStyle]="to.valueStyle">{{ to.value|date:'MMM dd, yyyy hh:mm:ss a' }}</span>
    </div>
  `,
  styles: [
    `
      .label {
        font-size: 1rem;
      }
      .value {
        font-size: 14px !important;
        color: #000 !important;
      }
      .text-white{
        color: #fff !important;
      }
    `,
  ],
})
export class LabelValueComponent extends FieldType {}
