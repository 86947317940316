import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-custom-autocomplete',
  template: `
    <label *ngIf="to.label" class="custom-label" [innerHTML]="to.label + (to.required ? ' <span class=&quot;text-danger&quot;>*</span>' : '')"></label>
    <mat-form-field appearance="fill" class="custom-material-input">
      <input
        matInput
        [formControl]="formControl"
        [formlyAttributes]="field"
        [matAutocomplete]="auto"
        [placeholder]="to.placeholder"
        autocomplete="off"
      />
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="handleOptionSelected($event)">
        <mat-option
          *ngFor="let option of to.options"
          [value]="option"
        >
          {{ option.title }}
        </mat-option>
      </mat-autocomplete>
      <ng-container #fieldComponent></ng-container>
      <button mat-icon-button matSuffix *ngIf="to['tooltip']" [matTooltip]="to['tooltip']" tabindex="-1" (click)="$event.stopPropagation()">
        <mat-icon>info_outline</mat-icon>
      </button>
      <mat-error *ngIf="showError">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
    </mat-form-field>
  `,
  styles: [`
    .custom-material-input {
      width: 100%;
    }
    .custom-input-wrapper {
      display: flex;
      flex-direction: column;
    }
    .custom-label {
      margin-bottom: 4px;
    }
    .custom-input {
      margin-top: 8px;
    }
    ::ng-deep .mat-form-field-wrapper {
      margin-bottom: 0px !important;
    }
    ::ng-deep .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
      height: 0em;
    }
  `],
})
export class FormlyCustomAutocompleteComponent extends FieldType {

  handleOptionSelected(event: any): void {
    const selectedValue = event.option.value;
    console.log('------------------selectedValue', selectedValue);
    // Check if a dynamic callback is defined in the Formly JSON
    if (this.to.onOptionSelected) {
      this.to.onOptionSelected(selectedValue);
    }
  }
}
